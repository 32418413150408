<template>
  <div class="trailer-player relative flex flex-col p-0">
    <div class="text-right">
      <button data-testid="close-player-modal" @click="closeModal">
        <Icon name="close" class="text-2xl text-grayscale-100" />
      </button>
    </div>

    <div class="sm:hidden text-grayscale-100 pl-4 mb-1">
      <h3 class="title-medium mb-0">
        {{ title }}
      </h3>
      <p class="subheading whitespace-pre-line">
        {{ subtitle }}
      </p>
    </div>

    <VideoPlayer
      ref="videoPlayerRef"
      data-testid="modal-player"
      :is-hide-controls="false"
      :is-auto-play="true"
      :options="{
        videoId: videoId,
        embedId: 'default',
        ...brightcove
      }"
      :mixpanel="mixpanel"
      class="mb-0"
      @on-play="onPlay"
      @on-auto-play="onPlay"
      @on-track-video-progress="onTrackVideoProgress"
    />

    <div class="flex flex-row justify-end sm:justify-between items-start mt-0 sm:mt-6">
      <div class="hidden sm:block text-grayscale-100 pl-4">
        <h3 class="title-medium mb-2">
          {{ title }}
        </h3>
        <p class="subheading whitespace-pre-line">
          {{ subtitle }}
        </p>
      </div>

      <slot />
    </div>
  </div>
</template>

<script>
  import gtm from '@/mixins/gtm'
  import brightcove from '@/mixins/brightcove'
  import VideoPlayer from '@/Components/Shared/VideoPlayer'
  import Icon from '@/Components/Shared/Icon'
  import { closeModal } from 'jenesius-vue-modal'

  export default {
    components: {
      VideoPlayer,
      Icon
    },
    mixins: [brightcove, gtm],

    props: {
      mixpanel: {
        type: Object,
        default: () => ({})
      },
      title: {
        type: String,
        required: true
      },
      subtitle: {
        type: String,
        required: true
      },
      videoId: {
        type: String,
        required: true
      },
      gtmPageName: {
        type: String,
        required: true
      },
      gtmSectionName: {
        type: String,
        required: true
      }
    },

    data() {
      return {
        // check if the video already started playing
        startedPlaying: false
      }
    },

    methods: {
      onPlay() {
        if (!this.startedPlaying) {
          this.gtmAddTrailerTracking(this.gtmPageName, this.gtmSectionName, this.title, 0)
          this.startedPlaying = true
        }
      },

      pauseVideo() {
        this.$refs.videoPlayerRef.pauseVideo()
        this.startedPlaying = false
      },

      playVideo() {
        this.$refs.videoPlayerRef.playVideo()
        this.startedPlaying = true
      },

      onTrackVideoProgress(percentage) {
        this.gtmAddTrailerTracking(this.gtmPageName, this.gtmSectionName, this.title, percentage)
      },

      closeModal() {
        closeModal()
      }
    }
  }
</script>

<style>
  .modal-container:has(.trailer-player) {
    background: rgba(0, 0, 0, 0.85) !important;
  }

  .modal-item:has(.trailer-player) {
    height: auto !important;
    max-height: 90vh !important;
    width: 100vw !important;
    max-width: none !important;
  }
  .modal-container .modal-item:has(.trailer-player) > div.trailer-player {
    height: 100% !important;
  }
  .modal-container .modal-item:has(.trailer-player) .video-player {
    aspect-ratio: 16/9;
    width: 100%;
  }

  @media (min-width: 768px) {
    .modal-item:has(.trailer-player) {
      width: 85vw !important;
      max-width: 1000px !important;
    }
  }

  @media (max-height: 670px) {
    .modal-item:has(.trailer-player) {
      width: 95vw !important;
    }
    .modal-container {
      align-items: center !important;
    }
    .modal-container .modal-item > div.trailer-player {
      padding: 0 !important;
    }
  }
  @media (max-height: 550px) {
    .modal-item:has(.trailer-player) {
      width: 95vw !important;
    }
  }
</style>
