<template>
  <div class="navbar-desktop hidden lg:flex flex-row items-center ml-8">
    <!-- Categories Dropdown -->
    <!-- ** list of categories providing core course browsing experience -->
    <div class="relative">
      <div
        data-testid="navbar-link-categories"
        class="cursor-pointer px-4"
        @click="$emit('toggleCategoriesDropdown')"
      >
        <p
          class="menu-link flex items-center"
          :class="{ 'underline' : isCategoriesDropdown }"
        >
          {{ $I18n.t('courses_list', i18nScope) }}
          <Icon
            name="Chevron"
            class="text-sm ml-2"
            :class="{'transform rotate-180 transition-transform duration-300' : isCategoriesDropdown}"
          />
        </p>
      </div>


      <CategoriesMenu
        :categories="categories"
        :is-categories-dropdown="isCategoriesDropdown"
      />
      <div
        v-show="isCategoriesDropdown"
        class="menu-container"
        @click.self="$emit('toggleCategoriesDropdown')"
      />
    </div>

    <!-- Instructors -->
    <Link
      data-testid="navbar-link-gifts"
      :href="$routes.instructors()"
      class="menu-link px-4"
    >
      {{ $I18n.t('instructors', i18nScope) }}
    </Link>

    <!-- <Link
      v-if="!isSubscribed"
      data-testid="navbar-link-gifts"
      :href="$routes.membership_landing()"
      class="menu-link px-4"
      @click="mixpanelTrackEvent()"
    >
      {{ $I18n.t('subscription', i18nScope)}}
    </Link> -->
    <!-- Gifts -->
    <Link
      data-testid="navbar-link-gifts"
      :href="$routes.gifts()"
      class="menu-link px-4"
      @click="mixpanelTrackEvent()"
    >
      {{ $I18n.t('gifts', i18nScope) }}
    </Link>

    <!-- Free Lessons -->
    <Link
      v-if="currentUser && !isSubscribed && !enrolled"
      data-testid="navbar-link-free-lessons"
      :href="$routes.free_lessons()"
      class="menu-link px-4"
      @click="mixpanelTrackEvent()"
    >
      {{ $I18n.t('free_lessons', i18nScope) }}
    </Link>

    <!-- Seasonal Dropdown -->
    <div
      v-if="seasonalIsActive"
      class="relative"
    >
      <div
        data-testid="navbar-link-seasonal"
        class="cursor-pointer px-4"
        @click="$emit('toggleSeasonalDropdown')"
      >
        <p
          class="menu-link flex items-center"
          :class="{ 'underline' : isSeasonalDropdown }"
        >
          {{ $I18n.t('seasonal.title', i18nScope) }}
          <Icon
            name="Chevron"
            class="text-sm ml-2"
            :class="{'transform rotate-180 transition-transform duration-300' : isSeasonalDropdown}"
          />
        </p>
      </div>

      <SeasonalMenu
        :is-seasonal-dropdown="isSeasonalDropdown"
      />
      <div
        v-show="isSeasonalDropdown"
        class="menu-container"
        @click.self="$emit('toggleSeasonalDropdown')"
      />
    </div>

    <!-- Membership offer -->
    <a
      v-if="showMembershipOffer"
      data-testid="navbar-link-membership-offer"
      href="https://page.createacademy.com/membership"
      target="_blank"
      class="menu-link px-4"
    >
      {{ $I18n.t('membership_offer', i18nScope) }}
    </a>
  </div>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import cookies from '@/mixins/cookies'
import mixpanel from '@/mixins/mixpanel'

import { Link } from '@inertiajs/vue3'

import Icon from '@/Components/Shared/Icon'
import CategoriesMenu from '@/Components/Shared/Objects/Navbar/Desktop/CategoriesMenu'
import SeasonalMenu from '@/Components/Shared/Objects/Navbar/Desktop/SeasonalMenu'


export default {

  components: {
    Link,
    Icon,
    CategoriesMenu,
    SeasonalMenu
  },
  mixins: [
    currentUser,
    cookies,
    mixpanel
  ],

  props: {
    categories: Array,

    isCategoriesDropdown: {
      type: Boolean,
      required: true
    },

    isSeasonalDropdown: {
      type: Boolean,
      required: true
    },

    seasonalIsActive: {
      type: Boolean,
      required: true
    }
  },

  emits: ['toggleCategoriesDropdown', 'toggleSeasonalDropdown'],

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  },

  methods: {
    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },

    mixpanelTrackEvent() {
      this.trackEvent('main_menu_gift', {
        userType: this.getUserType()
      })
    }
  }
}
</script>
