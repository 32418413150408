<template>
  <MenuPanel
    :name="'MENU'"
    :current-panel="isToggleGeneralMenu"
    class="overflow-y-scroll"
    @click-close="$emit('toggleGeneralMenu')"
  >
    <MenuItem
      :body="$I18n.t('categories', i18nScope)"
      class="mb-4"
      text
    >
      <CategoriesList :categories="categories" />
    </MenuItem>

    <MenuItem
      testid="navbar-link-instructors"
      :href="$routes.instructors()"
      :body="$I18n.t('instructors', i18nScope)"
    />

    <MenuItem
      testid="navbar-link-gifts"
      :href="$routes.gifts()"
      :body="$I18n.t('gifts', i18nScope)"
    />

    <MenuItem
      v-if="currentUser && !isSubscribed && !enrolled"
      testid="navbar-link-free-lessons"
      :href="$routes.free_lessons()"
      :body="$I18n.t('free_lessons', i18nScope)"
    />

    <MenuItem
      v-if="showMembershipOffer"
      testid="navbar-link-membership_offer"
      external
      href="https://page.createacademy.com/membership"
      :body="$I18n.t('membership_offer', i18nScope)"
    />

    <MenuItem
      v-if="seasonalIsActive"
      :body="$I18n.t('seasonal.title', i18nScope)"
      class="mb-2"
      toggler
      @click="$emit('toggleSeasonalMenu')"
    />

    <template v-if="!isSubscribed">
      <MenuItem
        testid="navbar-link-onboarding"
        :href="$routes.onboarding()"
        body="Subscribe"
      />
    </template>

    <!-- Account section if user is logged in -->
    <template v-if="currentUser">
      <Divider
        color="light"
        spacing="xs"
        class="my-2"
      />

      <MenuItem
        :body="$I18n.t('my_account', i18nScope)"
        class="mb-4"
        text
      >
        <div class="flex flex-col pl-2">
          <Link
            v-if="hasEnrollments && !isSubscribed"
            class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800"
            :href="$routes.root({ anchor: 'my-courses' })"
            @click="$emit('toggleGeneralMenu')"
          >
            {{ $I18n.t('home', i18nScope) }}
          </Link>

          <Link
            :href="$routes.user_collections()"
            class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 hover:underline"
          >
            {{ $I18n.t('collections', i18nScope) }}
          </Link>

          <ProfileLinks
            screen="mobile"
            class="mt-1"
          />

          <MenuItem
            data-testid="navbar-link-support"
            :href="$routes.support()"
            :body="$I18n.t('support', i18nScope)"
          />

          <LogoutButton class="subheading--medium text-danger" />
        </div>
      </MenuItem>
    </template>

    <template v-if="!currentUser">
      <MenuItem
        testid="navbar-link-signin"
        :href="$routes.new_user_session()"
        :body="$I18n.t('sign_in', i18nScope)"
      />
    </template>

    <SocialIcons class="mt-2" />
  </MenuPanel>
</template>

<script>

  import currentUser from '@/mixins/currentUser'
  import cookies from '@/mixins/cookies'
  import { Link } from '@inertiajs/vue3'

  import CategoriesList from '@/Components/Shared/Objects/Navbar/CategoriesList'
  import MenuItem from '@/Components/Shared/Objects/Navbar/Mobile/MenuItem'
  import MenuPanel from '@/Components/Shared/Objects/Navbar/Mobile/MenuPanel'
  import SocialIcons from '@/Components/Shared/Objects/Navbar/Mobile/SocialIcons'
  import ProfileLinks from '@/Components/Shared/Objects/Navbar/ProfileLinks'
  import LogoutButton from '@/Components/Shared/LogoutButton'
  import Divider from '@/Components/Shared/Divider'

  export default {

    components: {
      CategoriesList,
      MenuItem,
      MenuPanel,
      SocialIcons,
      ProfileLinks,
      LogoutButton,
      Divider,
      Link
    },
    mixins: [currentUser, cookies],

    props: {
      categories: Array,

      isToggleGeneralMenu: {
        type: Boolean,
        required: true
      },

      seasonalIsActive: {
        type: Boolean,
        required: true
      }
    },

    computed: {
      coursesListName() {
        return 'courses_list'
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.navbar' }
    }
  }
</script>
