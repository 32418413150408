<template>
  <form
    novalidate="true"
    class="checkout-panels"
    @submit.prevent="submit"
  >
    <Navbar
      :orderable="orderable"
      :i18n-scope="i18nScope"
    />

    <div class="panel-form">
      <div class="panel-form__container">
        <PaymentDetails
          v-show="!freeOrder"
          :form="form"
          :card-errors="card.errors"
          :submitters="submitters"
          :payment-request="paymentRequest"
          :i18n-scope="i18nScope"
        />

        <CouponDetails
          @validated="onCouponValidated"
          @reset="onCouponReset"
        />

        <BillingDetails
          :form="form"
          :errors="errors"
          :i18n-scope="i18nScope"
          :countries="countries"
        />

        <OrderSubmit
          :form="form"
          :submitters="submitters"
          :payment-request="paymentRequest"
          :humanized-amount="humanizedAmount"
          :errors="errors"
          :i18n-scope="i18nScope"
          :button-variant="buttonVariant"
        />
      </div>
    </div>

    <div
      class="panel-summary"
      :class="{ 'announcement-is-on' : showGeneralAnnouncement }"
    >
      <OrderSummaryToggler
        :title="$I18n.t('sections.order_summary_title', i18nScope)"
        :amount="amount"
        :rotate="isToggleSummary"
        class="panel-summary__toggler"
        @click="toggleSummary"
      />

      <div
        ref="orderSummary"
        class="panel-summary__container"
        :class="{'hidden': !isToggleSummary}"
      >
        <h2 class="hidden lg:block title-medium text-blue-800 mb-4">
          {{ $I18n.t('sections.order_summary_title', i18nScope) }}
        </h2>

        <OrderSummary
          :orderable="orderable"
          :show-gift-info="false"
          :i18n-scope="i18nScope"
          :gtm-page-name="gtmPageName"
        />

        <OrderBreakdown
          :show-discount-tag="discount > 0"
          :promotion-banner="promotionBanner"
          :voucher-code="form.voucher.code"
          :i18n-scope="i18nScope"
          :geo-price="orderable.geoPrice"
          :discount="discount"
          :geo-props="geoProps"
          class="mt-6 lg:mb-0 border-t border-blue-800 pt-4"
        />
      </div>
    </div>
  </form>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import dirtyForm from '@/mixins/dirtyForm'
import confirmLeave from '@/mixins/confirmLeave'
import gtm from '@/mixins/gtm'
import generalAnnouncement from '@/mixins/generalAnnouncement'
import overflowLogic from '@/mixins/overflowLogic'
import monetize from '@/mixins/monetize'
import { appsignal } from '@/packs/appsignal'

import Navbar from '@/Components/Orders/Navbar'
import PaymentDetails from '@/Components/Orders/PaymentDetails'
import BillingDetails from '@/Components/Orders/BillingDetails'
import CouponDetails from '@/Components/Orders/CouponDetails'
import OrderSummary from '@/Components/Orders/OrderSummary'
import OrderBreakdown from '@/Components/Orders/OrderBreakdown'
import OrderSubmit from '@/Components/Orders/OrderSubmit'
import OrderSummaryToggler from '@/Components/Orders/OrderSummaryToggler'

export default {
  mixins: [
    monetize,
    currentUser,
    dirtyForm('form.order'),
    confirmLeave,
    generalAnnouncement,
    overflowLogic,
    gtm
  ],

  props: {
    orderable: {
      type: Object,
      required: true
    },
    promotion: {
      type: Object,
      required: true
    },
    discountRatio: {
      type: Number,
      default: 0
    },
    geoProps: {
      type: Object,
      required: false
    },
    stripePublishableKey: {
      type: String,
      required: true
    },
    stripePaymentIntentId: {
      type: String,
      required: true
    },
    stripePaymentIntentClientSecret: {
      type: String,
      required: true
    },
    countries: {
      type: Array,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    }
  },

  data() {
    // Here we have to get the currentUser directly from the props instead
    // of using the currentUser computed property, because the data is evaluated
    // before computed properties are.
    const authUser = this.$page.props.auth.user

    this.originalAmount = this.promotion.amount
    this.originalDiscount = this.promotion?.discount || 0

    return {
      voucherPayload: {},

      // Keep track of order price (with or without discount)
      amount: this.originalAmount,
      discount: this.originalDiscount,

      // form
      form: {
        order: {
          billing_name: authUser?.account?.name || '',
          billing_address: '',
          billing_city: '',
          billing_post_code: '',
          billing_country: '',
          stripe_payment_intent_id: this.stripePaymentIntentId
        },
        voucher: {
          code: null
        },
        processing: false
      },

      // required fields - used for client side validations
      requiredFields: ['billing_name', 'billing_post_code', 'billing_country'],

      // used for client side errors
      localErrors: {},

      // toggle to open/close order summary tab on mobile + tablet
      isToggleSummary: false,

      // stripe instance
      stripe: null,

      // stripe card element (used for payment with card)
      card: {
        element: null,
        errors: null,
        valid: false,
        style: {
          base: {
            fontSmoothing: 'antialiased',
            fontFamily: 'Brandon Grotesque, Roboto, Open Sans, Segoe UI, sans-serif',
            fontWeight: '300',
            fontSize: '16px',
            backgroundColor: '#FFFFFF', // grayscale-100

            iconColor: '#0E1E38', // blue-800

            ':-webkit-autofill': {
              color: '#FFFFFF' // grayscale-100
            },
            '::placeholder': {
              color: '#393939' // grayscale-300
            }
          },
          invalid: {
            iconColor: '#D14242', // danger
            color: '#D14242' // danger
          },
          complete: {
            iconColor: '#31873D', // success
            color: '#31873D' // success
          }

        }
      },

      // stripe payment request element (used for payment with wallets)
      paymentRequest: {
        element: null,
        canMakePayment: null,
        type: null
      },

      // The submitter will be used to set the name of each submit button
      // We'll then use that to know if the form was submitted through the regular Card input, or through PaymentRequest (wallets)
      submitters: {
        card: 'cardSubmitter',
        paymentRequest: 'paymentRequestSubmitter',
        active: 'cardSubmitter'
      }
    }
  },

  computed: {
    // Form errors
    errors() {
      return this.$page.props.errors || this.localErrors
    },

    // Button style depending on if form is invalid or not
    buttonVariant() {
      return this.invalidForm(this.submitters.card) ? 'inactive' : 'blue'
    },

    promotionBanner() {
      return this.promotion?.banner
    },

    humanizedAmount() {
      return this.monetize(this.amount)
    },

    humanizedDiscount() {
      return this.monetize(this.discount)
    },

    freeOrder() {
      return this.amount === 0
    },

    submitLink() {
      return this.$routes.cart_order_index()
    }
  },

  watch: {
    amount(newValue) {
      this.paymentRequest.element?.update({
        total: {
          label: this.orderable.name,
          amount: newValue
        }
      })
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.orders.new' }

    // Used by the confirmLeave mixin
    this.formSubmitPath = [
      '/basket/orders', // legacy unnamed route
      this.$routes.cart_order_index(),
      this.$routes.free_cart_order_index()
    ]
  },

  // - Initialize Stripe
  // - Create and mount Stripe card element (for payments with card)
  // - Initialize Stripe payment request (for payments with wallets)
  mounted() {
    this.initializeStripe()
    this.initializeCardElement()
    this.initializePaymentRequest()
  },

  components: {
    Navbar,
    PaymentDetails,
    BillingDetails,
    CouponDetails,
    OrderSummary,
    OrderSummaryToggler,
    OrderBreakdown,
    OrderSubmit
  },

  methods: {
    onCouponValidated(payload) {
      if (payload.valid) {
        // save validated voucherify payload for gtm purposes
        this.voucherPayload = payload
      }
      if (payload.order) {
        this.discount = payload.order.total_discount_amount || 0
        this.amount = payload.order.total_amount
        this.form.voucher.code = payload.redeemableCode?.id
      } else {
        this.onCouponReset()
      }
    },

    onCouponReset() {
      this.voucherPayload = {}
      this.amount = this.originalAmount
      this.discount = this.originalDiscount
      this.form.voucher.code = null
    },

    // Form is invalid if:
    // - required fields are not filled
    // - card is not valid (if submitter is card)
    invalidForm(submitter) {
      for (const requiredField of this.requiredFields) {
        if (!this.form.order[requiredField]) return true
      }

      if (submitter === this.submitters.card && !this.freeOrder) return !this.card.valid

      return false
    },

    // Initialize the Stripe object
    initializeStripe() {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.stripePublishableKey)
    },

    // Initialize the Stripe card element (used for card payments)
    initializeCardElement() {
      this.card.element = this.stripe.elements().create('card', {
        hidePostalCode: true,
        style: this.card.style
      })
      this.card.element.mount('[data-card-element]')

      this.listenForCardChanges()
    },

    // Initialize the Stripe paymentRequest element (used for wallet payments)
    async initializePaymentRequest() {
      this.paymentRequest.element = this.stripe.paymentRequest({
        country: 'GB',
        currency: 'gbp',
        total: {
          label: this.orderable.name,
          amount: this.amount
        },
        requestPayerName: true,
        requestPayerEmail: true
      })

      this.paymentRequest.canMakePayment = await this.paymentRequest.element.canMakePayment()
      if (this.paymentRequest.canMakePayment) {
        this.paymentRequest.type = this.paymentRequestType(this.paymentRequest.canMakePayment)
      }

      this.listenForPaymentRequestEvents()
    },

    paymentRequestType(canMakePayment) {
      if (!canMakePayment) {
        return null
      } else if (canMakePayment.applePay) {
        return 'apple'
      } else if (canMakePayment.googlePay) {
        return 'google'
      } else {
        return 'card'
      }
    },

    // Submit form
    // - If form isn't valid, show errors and don't submit the form
    // - If it is a card payment, call submitForm
    // - If it is a wallet payment, show the wallet dialog
    // - submitter is the name of the clicked button: used to know if submitted through Card input or PaymentRequest
    submit() {
      this.form.processing = true
      this.localErrors = {}

      try {
        if (this.invalidForm(this.submitters.active)) {
          this.setLocalErrors(this.submitters.active)
          return
        }

        if (this.freeOrder) {
          this.$inertia.post(this.$routes.free_cart_order_index(), this.form, {
            preserveScroll: true,
            onSuccess: (page) => {
              this.gtmPurchaseStep(page)
            }
          })
        } else if (this.submitters.active === this.submitters.card) {
          this.submitForm(
            this.confirmCardPayment,
            this.onCardErrorHandler
          )
        } else {
          this.paymentRequest.element.show()
        }
      } catch (error) {
        console.error('Payment submission error:', error)
        const errorDetails = error.message || error.toString()

        // Report to Appsignal with context
        appsignal.sendError(error, span => {
          span.setAction('submit')
          span.setParams({
            submitter: this.submitters.active,
            isFreeOrder: this.freeOrder,
            amount: this.amount,
            orderable: this.orderable.name,
            hasVoucher: !!this.form.voucher.code,
            errorDetails
          })
        })

        this.$page.props.flash.alert = `${this.$I18n.t('payment_failed_default_error_message', this.i18nScope)} (Error: ${errorDetails})`
      } finally {
        this.form.processing = false
      }
    },

    // Submits the form to the backend
    submitForm(onSuccess, onError) {
      this.$inertia.post(this.submitLink, this.form, {
        preserveScroll: true,
        onSuccess: (page) => {
          // To avoid situations when Inertia triggers the onSuccess callback
          // incorrectly (for example, if the inertia error payload was not
          // properly set), we check the page props to confirm that the backend
          // response was indeed success, and thus we can proceed with the payment
          if (page?.props?.canConfirmPayment === true) {
            onSuccess()
          } else {
            onError()
          }
        },
        onError: onError
      })
    },

    visitOrderConfirmationPage() {
      // Set formDirty to false so that the confirmLeave mixin doesn't show the
      // leave page alert
      this.formDirty = false

      // Show the Order confirmation page to the customer
      // There's a risk of the customer closing the window before callback
      // execution.
      // So, we now listen for the payment_intent.succeeded event in the
      // backend that handles the business post-payment actions, like
      // marking the Order as paid, giving the Customer access to the Course, etc.
      this.$inertia.visit(this.$routes.confirmation_cart_order_index(), {
        onSuccess: (page) => {
          this.gtmPurchaseStep(page)
        }
      })
    },

    // Try to charge the card, and calls handlePayment with the response
    async confirmCardPayment() {
      const response = await this.stripe.confirmCardPayment(this.stripePaymentIntentClientSecret, {
        payment_method: {
          card: this.card.element,
          billing_details: {
            name: this.form.order.billing_name,
            email: this.currentUser.email,
            address: {
              city: this.form.order.billing_city,
              country: this.form.order.billing_country,
              line1: this.form.order.billing_address,
              postal_code: this.form.order.billing_post_code
            }
          }
        }
      })

      this.handlePayment(response)
    },

    // Try to charge the wallet, and:
    // - calls event.complete with 'fail' or 'success' based on the result - this will update the wallet dialog accordingly
    // - calls handlePayment with the response
    async confirmPaymentRequestPayment(event) {
      // Confirm the PaymentIntent with the payment method returned from the payment request.
      const { error } = await this.stripe.confirmCardPayment(
        this.stripePaymentIntentClientSecret,
        { payment_method: event.paymentMethod.id },
        { handleActions: false }
      )

      if (error) {
        // Report to the browser that the payment failed.
        event.complete('fail')
        this.handlePayment({ error })
      } else {
        // Report to the browser that the confirmation was successful, prompting
        // it to close the browser payment method collection interface.
        event.complete('success')
        // Let Stripe.js handle the rest of the payment flow, including 3D Secure if needed.
        const response = await this.stripe.confirmCardPayment(this.stripePaymentIntentClientSecret)
        this.handlePayment(response)
      }
    },

    // Handle new PaymentIntent result
    // - If it failed, shows an error alert with the error message
    // - If it succeeded, redirects the User to the Order confirmation page
    handlePayment(paymentResponse) {
      const {paymentIntent, error} = paymentResponse

      if (error) {
        // Report payment error to Appsignal
        appsignal.sendError(error, span => {
          span.setAction('handlePayment')
          span.setParams({
            submitter: this.submitters.active,
            amount: this.amount,
            orderable: this.orderable.name,
            stripeError: error.message,
            paymentIntentId: this.stripePaymentIntentId
          })
        })

        this.$page.props.flash.alert = error.message
        this.form.processing = false

      } else if (paymentIntent.status === 'succeeded') {
        this.visitOrderConfirmationPage()

      } else {
        const unknownError = new Error('Payment intent returned with unexpected status')

        // Report unexpected payment status to Appsignal
        appsignal.sendError(unknownError, span => {
          span.setAction('handlePayment')
          span.setParams({
            submitter: this.submitters.active,
            amount: this.amount,
            orderable: this.orderable.name,
            paymentIntentStatus: paymentIntent.status,
            paymentIntentId: this.stripePaymentIntentId
          })
        })

        this.$page.props.flash.alert = this.$I18n.t('payment_failed_default_error_message', this.i18nScope)
        this.form.processing = false
      }
    },

    // Listen for changes on the card input, and:
    // - update the card state accordingly
    // - marks the form state as dirty
    listenForCardChanges() {
      this.card.element.on('change', (card) => {
        this.card.valid = !card.error && card.complete
        this.card.errors = card.error
        this.formDirty = true

        if (this.card.valid) {
          this.gtmAddPaymentInfo(this.orderable, card.brand)
        }
      })
    },

    // Listen for payment request events
    // Submits the form to the backend after customer interacted with the wallet dialog
    listenForPaymentRequestEvents() {
      // The cancel event is emitted from a PaymentRequest when the browser's payment interface is dismissed.
      this.paymentRequest.element.on('cancel', () => {
        this.form.processing = false
      })

      // Stripe.js automatically creates a PaymentMethod after the customer is done interacting with the browser's payment interface.
      // To access the created PaymentMethod, listen for this event.
      this.paymentRequest.element.on('paymentmethod', async (event) => {
        this.submitForm(
          () => { this.confirmPaymentRequestPayment(event) },
          () => { this.onPaymentRequestErrorHandler(event) }
        )
      })
    },

    // Set form errors during client side validation
    setLocalErrors(submitter) {
      for (const requiredField of this.requiredFields) {
        if (!this.form.order[requiredField]) this.localErrors[requiredField] = [this.$I18n.t('activerecord.errors.messages.blank_generic')]
      }

      if (submitter === this.submitters.card) this.setDefaultCardError()

      this.$page.props.flash.alert = this.$I18n.t('services.orders.create.error')
    },

    // Handle errors if submit was made using card input
    onCardErrorHandler() {
      this.setDefaultCardError()
      this.form.processing = false
    },

    // Handle errors if submit was made using wallet
    onPaymentRequestErrorHandler(event) {
      event.complete('fail')
      this.form.processing = false
    },

    // If request failed and card is not valid show default card error, otherwise Users will only
    // see card errors when the Stripe request is made
    setDefaultCardError() {
       if (!this.freeOrder && !this.card.valid && !this.card.errors) {
        this.card.errors = { message: this.$I18n.t('form.inputs.card.required', this.i18nScope) }
      }
    },

    // Toggle Mobile Order Summary Panel
    toggleSummary() {
      this.isToggleSummary = !this.isToggleSummary
      if (this.isToggleSummary) {
        let navbarHeight = document.querySelector('[data-navbar]').clientHeight
        let orderTogglerHeight = document.querySelector('[data-order-toggler]').clientHeight
        let paymentButtonHeight = document.querySelector('[type=submit]').clientHeight

        // On Campaigns, when generalAnnouncement is on we need to adjust the calc of the position
        let overflowElements = navbarHeight + orderTogglerHeight + paymentButtonHeight

        if (this.showGeneralAnnouncement){
          let announcementHeight = document.querySelector('[data-announcement]').clientHeight
          overflowElements = overflowElements + announcementHeight
        }

        let overflowHeight = window.innerHeight - overflowElements

        this.$refs.orderSummary.style.height = overflowHeight + 'px'
        this.setScrollOverflow('hidden')
      } else {
        this.$refs.orderSummary.style.height = 'initial'
        this.setScrollOverflow('auto')
      }
    },

    gtmPurchaseStep(page) {
      const { props } = page

      // puts on the datalayer info about the current step of the purchase
      this.gtmAddPurchaseStep(props.orderable, props.order, this.voucherPayload)
    }
  }
}
</script>
