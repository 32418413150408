<template>
  <div>
    <div class="mx-auto px-0">
      <MetaTags :meta-tags="metaTags" />

      <!-- Hero -->

      <main
        class="flex flex-row items-start bg-blue-800 relative pb-12 min-h-[800px] overflow-hidden"
      >
        <!-- Content Section (2/3 width) -->
        <div class="w-full lg:w-2/3 flex flex-col md:px-16 md:py-20 relative z-10 px-0">
          <header class="w-full -mx-0 md:mx-0">
            <img
              :src="imageUrl('membership-hero-mobile.png')"
              alt="Create Academy membership hero"
              class="w-full mb-8 md:hidden"
            />
            <h1 class="font-sans text-center lg:text-left" :style="headingStyle">
              Your learning journey starts here
            </h1>
          </header>
          <section class="w-full px-4 md:px-0">
            <p
              class="font-sans mt-4 lg:mt-8 md:mt-3 w-full md:w-3/4 text-center lg:text-left"
              :style="{
                color: '#F6F7F9',
                fontSize: isMobile ? '18px' : '28px',
                fontStyle: 'normal',
                fontWeight: isMobile ? '390' : '400',
                lineHeight: isMobile ? '24px' : '37px'
              }"
            >
              Build your creative confidence, one lesson at a time with unlimited access to all
              courses.
            </p>
            <!-- First stats section - visible only on mobile, above button -->
            <section class="flex justify-center gap-8 mt-8 mb-8 w-full md:hidden">
              <article class="flex flex-col min-h-[79px] items-center">
                <h2
                  class="font-sans text-center"
                  :style="{
                    color: '#FFF',
                    fontSize: '28px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '133%'
                  }"
                >
                  +1,200
                </h2>
                <p
                  class="mt-2.5 font-sans text-center"
                  :style="{
                    color: '#F6F7F9',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    lineHeight: '28px'
                  }"
                >
                  Immersive Lessons
                </p>
              </article>
              <article class="flex flex-col min-h-[79px] items-center">
                <h2
                  class="font-sans text-center"
                  :style="{
                    color: '#FFF',
                    fontSize: '28px',
                    fontStyle: 'normal',
                    fontWeight: '420',
                    lineHeight: '133%'
                  }"
                >
                  +45
                </h2>
                <p
                  class="mt-2.5 font-sans text-center"
                  :style="{
                    color: '#F6F7F9',
                    fontSize: '14px',
                    fontStyle: 'normal',
                    fontWeight: '390',
                    lineHeight: '28px'
                  }"
                >
                  Industry Experts
                </p>
              </article>
            </section>

            <form class="flex flex-col mt-2 text-base items-center lg:items-start">
              <div class="w-fit">
                <Link
                  class="button button--yellow inlineBlockLink max-w-full uppercase"
                  :href="currentUser ? $routes.membership_checkout() : $routes.onboarding()"
                  @click="onCheckoutClick(2)"
                >
                  <span class="text-base">
                    Join for
                    {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month
                  </span>
                </Link>
              </div>
              <p
                class="mt-3 font-sans text-center lg:text-left w-full"
                style="
                  color: #e8ebf0;
                  font-size: 16px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 24px;
                "
              >
                (Billed annually)
              </p>
            </form>
          </section>
          <!-- Second stats section - visible only on desktop -->
          <section class="hidden md:flex gap-8 mt-8 w-full">
            <article class="flex flex-col min-h-[79px]">
              <h2
                class="font-sans"
                style="
                  color: #fff;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 133%;
                "
              >
                +1,200
              </h2>
              <p
                class="mt-2.5 font-sans"
                style="
                  color: #f6f7f9;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 28px;
                "
              >
                Immersive Lessons
              </p>
            </article>
            <article class="flex flex-col min-h-[79px]">
              <h2
                class="font-sans"
                style="
                  color: #fff;
                  font-size: 40px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 133%;
                "
              >
                +45
              </h2>
              <p
                class="mt-2.5 font-sans"
                style="
                  color: #f6f7f9;
                  font-size: 20px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: 28px;
                "
              >
                Industry Experts
              </p>
            </article>
          </section>
        </div>

        <!-- right section bg image -->
        <div
          class="absolute hero-image hidden lg:block"
          :style="{
            backgroundImage: `url('${imageUrl('membership-hero.png')}')`
          }"
        />
      </main>

      <!-- Video Player -->
      <section class="bg-yellow-100 py-16 w-full">
        <h2 class="text-center text-blue-800 title-largest">
          Find the inspiration you need to flourish
        </h2>
        <p
          class="text-center"
          :style="{
            color: '#0E1E38',
            fontSize: isMobile ? '16px' : '24px',
            fontStyle: 'normal',
            fontWeight: isMobile ? '300' : 'light',
            lineHeight: isMobile ? '170%' : 'relaxed',
            letterSpacing: isMobile ? '0.16px' : 'normal',
            marginBottom: isMobile ? '20px' : '44px'
          }"
        >
          We're here to help you unlock your creativity
        </p>
        <div class="px-4 md:px-16">
          <VideoPlayer
            data-testid="instructor-video-player"
            class="mb-4 lg:mb-8"
            :is-auto-play="false"
            :options="{
              accountId: 6222962662001,
              playerId: brightcove.lessonPlayer.playerId,
              videoId: 6322144530112,
              embedId: 'default'
            }"
            :mixpanel="{ type: 'landing', videoId: 6322144530112 }"
          />
        </div>
      </section>

      <!-- Feature blocks -->
      <section class="p-6 py-16 w-full mx-auto flex flex-col bg-blue-100">
        <div class="mx-auto max-w-lg mb-12 text-center">
          <div class="title-largest text-blue-800" style="text-wrap: balance">
            Unlock your creativity with
          </div>
          <div class="font-normal text-2xl mt-4">
            Over <span class="text-yellow-800 font-bold">400 hours</span> of immersive learning to
            transform the way you live
          </div>
        </div>

        <!-- Block one -->
        <div class="flex flex-col lg:flex-row items-center w-full mb-8 lg:mb-0">
          <div class="w-full lg:w-1/2 p-4 lg:p-6">
            <div class="w-full">
              <img
                loading="lazy"
                :data-src="featureBlockOne.url"
                :srcset="featureBlockOne.srcset"
                :alt="$I18n.t('banner_image_alt', i18nScope)"
                class="image-cover lazyload"
              />
            </div>
          </div>

          <div class="w-full lg:w-1/2 p-4 lg:p-6 text-center">
            <img loading="lazy" :data-src="createStar" alt="CA" class="mx-auto lazyload mb-6" />
            <div class="title-largest">Unlimited access</div>
            <div class="uppercase text-gray-600 text-md font-normal mt-1 tracking-wide">
              To all courses and resources
            </div>
          </div>
        </div>

        <!-- Block two -->
        <div class="flex flex-col-reverse lg:flex-row items-center w-full mb-8 lg:mb-0">
          <div class="w-full lg:w-1/2 p-4 lg:p-6 text-center">
            <img loading="lazy" :data-src="createStar" alt="CA" class="mx-auto lazyload mb-6" />
            <div class="title-largest">Expert instruction</div>
            <div class="uppercase text-gray-600 text-md font-normal mt-1 tracking-wide">
              From the best designers, makers, growers & Chefs
            </div>
          </div>
          <div class="w-full lg:w-1/2 p-4 lg:p-6">
            <div class="w-full">
              <img
                loading="lazy"
                :data-src="featureBlockTwo.url"
                :srcset="featureBlockTwo.srcset"
                :alt="$I18n.t('banner_image_alt', i18nScope)"
                class="image-cover lazyload"
              />
            </div>
          </div>
        </div>

        <!-- Block three -->
        <div class="flex flex-col lg:flex-row items-center w-full mb-8 lg:mb-0">
          <div class="w-full lg:w-1/2 p-4 lg:p-6">
            <div class="w-full">
              <img
                loading="lazy"
                :data-src="featureBlockThree.url"
                :srcset="featureBlockThree.srcset"
                :alt="$I18n.t('banner_image_alt', i18nScope)"
                class="image-cover lazyload"
              />
            </div>
          </div>
          <div class="w-full lg:w-1/2 p-4 lg:p-6 text-center">
            <img loading="lazy" :data-src="createStar" alt="CA" class="mx-auto lazyload mb-6" />
            <div class="title-largest">New content</div>
            <div class="uppercase text-gray-600 text-md font-normal mt-0 lg:mt-2 tracking-wide">
              Added each month to help you develop your skills
            </div>
          </div>
        </div>

        <div class="max-w-2xl mx-auto mt-6 lg:mt-16 text-center">
          <div
            class="font-normal text-base lg:text-2xl text-blue-800 mb-2 lg:mb-3 tracking-wide lg:tracking-wider"
            v-html="membershipPlan.description"
          />
          <div
            class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-3"
          >
            <Link
              class="button button--yellow inlineBlockLink w-80 max-w-full"
              :href="currentUser ? $routes.membership_checkout() : $routes.onboarding()"
              @click="onCheckoutClick(2)"
            >
              <span class="text-base">
                <!-- Join for
                {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month -->
                SUBSCRIBE
              </span>
            </Link>
            <Link
              class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full"
              :href="$routes.gifts() + '#membershipSection'"
              @click="onGiftClick(2)"
            >
              <span class="text-base">GIFT SUBSCRIPTION </span>
            </Link>
          </div>
          <!-- <div class="font-light text-blue-800 text-xs lg:text-base">
            (Billed annually)
          </div> -->
        </div>
      </section>

      <!-- Join our community -->
      <section class="p-6 py-16 w-full mx-auto flex flex-col">
        <div class="mx-auto max-w-lg mb-12 text-center">
          <div class="title-largest text-blue-800">
            Join our community of over
            <span class="text-yellow-800">120,000</span> learners
          </div>
        </div>
        <div class="lg:flex lg:items-start mx-auto w-full">
          <!-- Block one -->
          <div class="w-full mb-8 lg:mb-0 lg:w-1/3 px-4">
            <div class="w-full h-64">
              <img
                loading="lazy"
                :data-src="communityBlockOne.url"
                :srcset="communityBlockOne.srcset"
                :alt="$I18n.t('banner_image_alt', i18nScope)"
                class="image-cover lazyload"
              />
            </div>
            <div
              class="mt-4 title-gigantic transform rotate-180 text-right tracking-tighter mb-2 sm:mb-0"
            >
              ,,
            </div>
            <div class="-mt-8 lg:-mt-6 title-large">
              I would never have been able to make this if it weren't for Create Academy.
            </div>
            <div class="uppercase text-yellow-800 text-md font-normal mt-0 lg:mt-2 tracking-wide">
              From Nicola
            </div>
          </div>
          <!-- Block two -->
          <div class="w-full mb-8 lg:mb-0 lg:w-1/3 px-4">
            <div class="w-full h-64">
              <img
                loading="lazy"
                :data-src="communityBlockTwo.url"
                :srcset="communityBlockTwo.srcset"
                :alt="$I18n.t('banner_image_alt', i18nScope)"
                class="image-cover lazyload"
              />
            </div>
            <div class="mt-4 title-gigantic transform rotate-180 text-right tracking-tighter mb-2">
              ,,
            </div>
            <div class="-mt-8 lg:-mt-6 title-large">
              Create Academy's online courses are brilliant for me. It allows the opportunity of
              learning from some wonderfully talented people, from the other side of the world, at a
              time to suit me.
            </div>
            <div class="uppercase text-yellow-800 text-md font-normal mt-0 lg:mt-2 tracking-wide">
              From DI
            </div>
          </div>
          <!-- Block three -->
          <div class="w-full mb-8 lg:mb-0 lg:w-1/3 px-4">
            <div class="w-full h-64">
              <img
                loading="lazy"
                :data-src="communityBlockThree.url"
                :srcset="communityBlockThree.srcset"
                :alt="$I18n.t('banner_image_alt', i18nScope)"
                class="image-cover lazyload"
              />
            </div>
            <div class="mt-4 title-gigantic transform rotate-180 text-right tracking-tighter mb-2">
              ,,
            </div>
            <div class="-mt-8 lg:-mt-6 title-large">
              Such great value because you come out with loads of inspiring projects to get you
              started and actionable advice.
            </div>
            <div class="uppercase text-yellow-800 text-md font-normal mt-0 lg:mt-2 tracking-wide">
              From Bert
            </div>
          </div>
        </div>
      </section>

      <!-- Our story -->
      <section class="bg-yellow-100 px-10 py-16 w-full mx-auto flex flex-col">
        <div class="lefty">
          <div class="title-largest mb-2">Our story</div>
          <div class="mb-6 max-w-lg text-xl font-normal">
            At Create Academy we believe everyone is creative, and we're on a mission to connect you
            to your creativity.
          </div>
        </div>

        <div class="mx-auto max-w-xl undent my-4 text-xl font-normal">
          Over the past four years, we've worked with more than 40 world-renowned makers, designers,
          gardeners, photographers and chefs to make this possible. We're passionate about giving
          access to expertise in industries that have been traditionally guarded, building a world
          of creativity where you can learn new skills, develop your confidence and find inspiration
          from the best.
        </div>

        <div class="mx-auto max-w-xl my-4">
          <div class="title-largest mb-2 text-yellow-800">
            Now, we're offering all access membership. All courses. All resources. All the learning.
            In one membership.
          </div>
        </div>

        <div class="mx-auto max-w-xl my-4">
          <div class="title-largest mb-2 text-yellow-800">
            If we are all creative, then creativity is for everyone.
          </div>
          <div class="mt-6">
            <Link
              data-testid="flex pb-1 border-b border-yellow-800"
              class="font-normal text-gray-800 border-b border-yellow-800 text-xl"
              :href="$routes.about_us()"
              @click="onCheckoutClick(3)"
            >
              <span class="">Find out more</span>
              <Icon name="Arrow" class="text-lg font-semibold ml-1" />
            </Link>
          </div>
        </div>
      </section>
      <section
        class="bg-blue-500 h-96 p-6 w-full mx-auto flex flex-col justify-center items-center"
      >
        <div class="mx-auto max-w-2xl text-center">
          <div
            class="text-xl lg:title-largester mb-6 text-white"
            v-html="membershipPlan.description"
          />

          <div
            class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
          >
            <Link
              class="button button--yellow inlineBlockLink w-80 max-w-full"
              :href="currentUser ? $routes.membership_checkout() : $routes.onboarding()"
              @click="onCheckoutClick(4)"
            >
              <span class="text-base">
                Join for
                {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month
              </span>
            </Link>
            <Link
              class="button button--yellow button--yellow-outline inlineBlockLink w-80 max-w-full group"
              :href="$routes.gifts() + '#membershipSection'"
              @click="onGiftClick(3)"
            >
              <span class="text-base text-white group-hover:text-yellow-800"
                >GIFT SUBSCRIPTION
              </span>
            </Link>
          </div>
          <!-- <div class="mt-2 font-light text-lg text-white">(Billed annually)</div> -->
        </div>
      </section>

      <!-- FAQs -->
      <section class="bg-white py-20 px-10 w-full sm:w-4/5 max-w-5xl mx-auto">
        <SerifHeader
          title="FAQs"
          heading-level="h2"
          content-align="center"
          title-size="large"
          color="green"
        />
        <Faq :gtm-page-name="'Membership Landing'" :questions="faqs" class="w-full mx-auto" />
      </section>
    </div>
  </div>
</template>

<script>
  import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
  import Icon from '@/Components/Shared/Icon'
  import { metaTags } from '@/mixins/metaTags'
  import MetaTags from '@/Components/Shared/MetaTags'
  import monetize from '@/mixins/monetize'
  import currentUser from '@/mixins/currentUser'
  import { Link } from '@inertiajs/vue3'
  import VideoPlayer from '@/Components/Shared/VideoPlayer'
  import mixpanel from '@/mixins/mixpanel'
  import abTest from '@/mixins/abTest'
  import Faq from '@/Components/Shared/Faq/Index'
  import cdn from '@/mixins/cdn'

  export default {
    components: {
      SerifHeader,
      Icon,
      MetaTags,
      Link,
      VideoPlayer,
      Faq
    },
    mixins: [metaTags, monetize, currentUser, mixpanel, abTest, cdn],
    props: {
      membershipPlan: {
        type: Object,
        required: true
      },
      brightcove: {
        type: Object,
        required: true
      },
      geoProps: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        isMobile: false,
        debugRotation: -15,
        debugOrigin: 'center center'
      }
    },
    computed: {
      // AB TEST SETUP
      targetTest() {
        return 'membership_landing'
      },
      faqs() {
        return [
          {
            q: 'When and where do Create Academy courses take place?',
            a: 'All of our courses are online, meaning that you are able to watch them whenever and wherever suits you best. There is no beginning or end date and they\'re completely self-paced so that you can stop, start and pause each lesson to fit around your own schedule.'
          },
          {
            q: 'How will I get access to the courses?',
            a: 'You\'ll access our full course library via your Create Academy account - so you\'ll need to log in or create an account when purchasing your subscription.'
          },
          {
            q: 'How long does my subscription last?',
            a: 'Subscriptions renew on an annual basis.'
          },
          {
            q: 'When will I be charged?',
            a: 'On the day you join, you\'ll pay upfront for the upcoming year, and your renewal date will be 12 months from the day you join.'
          },
          {
            q: 'What if I\'ve previously purchased an individual course?',
            a: 'Any courses you already own before subscribing will not be affected, and you can continue to enjoy them as before. If you choose to end your subscription you\'ll retain access to any courses you purchased before subscribing.'
          },
          {
            q: 'How do I cancel my subscription?',
            a: 'Your subscription comes with a 30 day money back guarantee, so within 30 days of purchase you can cancel and get a refund by simply emailing info@createacademy.com. Similarly, if you would like to cancel so your subscription doesn\'t automatically renew after 12 months, please email info@createacademy.com at any time.'
          }
        ]
      },
      headingStyle() {
        return {
          color: '#F6F7F9',
          fontSize: this.isMobile ? '30px' : '55px',
          fontStyle: 'normal',
          fontWeight: this.isMobile ? '450' : '600',
          lineHeight: this.isMobile ? '41px' : '139%',
          letterSpacing: this.isMobile ? '5px' : '8.25px',
          textTransform: 'uppercase'
        }
      }
    },
    mounted() {
      this.trackEvent('membership_landing_view', {
        userType: this.getUserType(),
        abTest: this.activeAbTest(this.targetTest) // associate this event with the active AB test
      })

      // Set initial value
      this.isMobile = window.innerWidth < 768

      // Add resize listener
      window.addEventListener('resize', () => {
        this.isMobile = window.innerWidth < 768
      })
    },
    created() {
      this.i18nScope = { scope: 'pages.membership' }
      this.metaTags = {
        title: 'Join Create Academy | Online Interior Design Courses & Community',
        description:
          'Transform your creative passion into expertise with Create Academy\'s online interior design courses. Join 120,000+ learners and access expert-led tutorials, exclusive content, and a supportive creative community.',
        robots: 'index, follow',
        og: {
          title: 'Join Create Academy | Online Interior Design Courses & Community',
          description:
            'Transform your creative passion into expertise with Create Academy\'s online interior design courses. Join 120,000+ learners and access expert-led tutorials, exclusive content, and a supportive creative community.',
          type: 'website'
        }
      }

      this.communityBlockOne = {
        url: this.imageUrl('wreath.webp'),
        sizes: {
          large: this.imageUrl('wreath.webp'),
          medium: this.imageUrl('wreath.webp'),
          small: this.imageUrl('wreath.webp')
        }
      }

      this.communityBlockTwo = {
        url: this.imageUrl('Headboard.webp'),
        sizes: {
          large: this.imageUrl('Headboard.webp'),
          medium: this.imageUrl('Headboard.webp'),
          small: this.imageUrl('Headboard.webp')
        }
      }

      this.communityBlockThree = {
        url: this.imageUrl('Curtains.webp'),
        sizes: {
          large: this.imageUrl('Curtains.webp'),
          medium: this.imageUrl('Curtains.webp'),
          small: this.imageUrl('Curtains.webp')
        }
      }

      this.featureBlockOne = {
        url: this.imageUrl('featureOne.webp'),
        sizes: {
          large: this.imageUrl('featureOne.webp'),
          medium: this.imageUrl('featureOne.webp'),
          small: this.imageUrl('featureOne.webp')
        }
      }

      this.featureBlockTwo = {
        url: this.imageUrl('featureTwo.webp'),
        sizes: {
          large: this.imageUrl('featureTwo.webp'),
          medium: this.imageUrl('featureTwoMobile.webp'),
          small: this.imageUrl('featureTwoMobile.webp')
        }
      }

      this.featureBlockThree = {
        url: this.imageUrl('featureThree.webp'),
        sizes: {
          large: this.imageUrl('featureThree.webp'),
          medium: this.imageUrl('featureThree.webp'),
          small: this.imageUrl('featureThree.webp')
        }
      }

      this.createStar = this.imageUrl('CaStar.svg')
    },
    beforeUnmount() {
      // Clean up resize listener
      window.removeEventListener('resize', () => {
        this.isMobile = window.innerWidth < 768
      })
    },
    methods: {
      getUserType() {
        return this.currentUser ? 'authed' : 'anonymous'
      },
      getGeoPrice(gbpBasePrice) {
        return this.geoMonetize(
          gbpBasePrice * this.geoProps.exchangeRate,
          this.geoProps.currencySymbol,
          0
        )
      },
      onCheckoutClick(buttonOrder) {
        this.trackEvent('membership_checkout', {
          userType: this.getUserType(),
          buttonOrder: buttonOrder
        })
        this.trackEvent('subscribe_button', {
          userType: this.getUserType(),
          page: window.location.pathname,
          component: 'Promo_uk Page CTA'
        })
      },
      onGiftClick(buttonOrder) {
        this.trackEvent('membership_gift', {
          userType: this.getUserType(),
          buttonOrder: buttonOrder
        })
      }
    }
  }
</script>

<style scoped>
  .hero-image {
    top: -420px;
    right: -350px;
    width: 1700px;
    height: calc(100% + 420px);
    background-size: cover;
    background-position: top right;
    transform: translateX(10%);
    z-index: 10;
  }

  @media (max-width: 1800px) {
    .hero-image {
      width: 1400px;
      top: -300px;
      height: calc(100% + 300px);
    }
  }

  @media (max-width: 1600px) {
    .hero-image {
      width: 1400px;
      top: -300px;
      right: -400px;
      height: calc(100% + 300px);
    }
  }

  @media (max-width: 1300px) {
    .hero-image {
      width: 1350px;
      right: -460px;
      top: -190px;
      height: calc(100% + 190px);
    }
  }
</style>
