<template>
  <div>
    <MetaTags :meta-tags="metaTags" />

    <div data-testid="banner-section">
      <Banner
        uid="instructor"
        :image-src="instructor.bannerImage"
        :image-alt="$I18n.t('instructor_banner', i18nScope)"
        height="medium"
        content-align="center"
        vertical-align="center"
        :darken="false"
      />
    </div>

    <div class="container py-0 relative z-10">
      <div
        class="mx-auto md:mx-0 w-64 h-64 -mt-32 sm:w-72 sm:h-72 sm:-mt-36 md:w-60 md:h-60 md:-mt-30 xl:w-80 xl:h-80 xl:-mt-40 rounded-full bg-grayscale-300 mb-8"
      >
        <img
          loading="lazy"
          :data-testid="`${instructor.id}-avatar`"
          :src="instructor.avatar"
          :alt="$I18n.t('instructor_avatar', i18nScope)"
          class="image-cover w-64 h-64 sm:w-72 sm:h-72 md:w-60 md:h-60 xl:w-80 xl:h-80 rounded-full"
        />
      </div>

      <div class="text-center md:text-left md:ml-72 xl:ml-96 md:-mt-28 xl:-mt-40">
        <h2 data-testid="instructor-title" class="title-xl text-grayscale-900 mt-4 md:mt-20">
          {{ instructor.name }}
        </h2>

        <p class="text-xl font-normal m-0 byline-clamp">
          {{ instructor.byline }}
        </p>

        <div class="flex flex-col items-center md:items-start gap-1 my-4">
          <a
            v-for="social in existingSocials(instructor.socials)"
            :key="social.key"
            class="group rounded-lg px-2 py-1 bg-transparent -mx-2 flex gap-2 text-gray-700 hover:text-gray-900 transition-all hover:bg-gray-100"
            :href="social.url"
          >
            <Icon
              :name="social.icon"
              class="text-grayscale-700 text-xl transition-all group-hover:text-grayscale-900"
            />
            <span class="transition-all">{{ social.text ? social.text : social.url }}</span>
          </a>
        </div>
      </div>
    </div>

    <div class="container max-w-5xl">
      <p class="text-left text-lg mb-4 lg:mb-0">
        {{ instructor.description }}
      </p>
    </div>

    <div v-if="instructor.videoId" class="container max-w-5xl py-0">
      <h3 class="title-large text-left text-grayscale-900 my-4">Meet {{ instructor.name }}</h3>

      <div data-testid="instructor" class="flex flex-col mx-auto my-8">
        <VideoPlayer
          data-testid="instructor-video-player"
          class="mb-4 lg:mb-8"
          :is-auto-play="false"
          :options="{
            accountId: brightcove.lessonPlayer.accountId,
            playerId: brightcove.lessonPlayer.playerId,
            videoId: instructor.videoId,
            embedId: 'default'
          }"
          :mixpanel="{ type: 'instructor', videoId: instructor.videoId }"
        />
      </div>
    </div>

    <div class="container max-w-5xl py-0">
      <div class="flex flex-col">
        <div class="relative mb-4 lg:mb-8">
          <h1
            data-testid="instructor-title"
            class="title-largest text-left text-grayscale-900 my-4 inline-block bg-white z-10 relative pr-8"
          >
            {{ possessiveForm(instructor.name) }} Online Course{{
              instructor.courses.length !== 1 ? 's' : ''
            }}
          </h1>
          <Divider class="absolute top-7 w-full left-0 h-px" height="regular" spacing="xs" />
        </div>

        <InstructorCourse v-for="course in instructor.courses" :key="course.id" :course="course" />
      </div>
    </div>
  </div>
</template>

<script>
  import Layout from '@/Layouts/TransparentLayout'

  import brightcove from '@/mixins/brightcove'
  import { metaTags } from '@/mixins/metaTags'

  import Banner from '@/Components/Shared/Banner'
  import Divider from '@/Components/Shared/Divider'
  import Icon from '@/Components/Shared/Icon'
  import InstructorCourse from '@/Components/Shared/Instructor/InstructorCourse'
  import MetaTags from '@/Components/Shared/MetaTags'
  // import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
  import VideoPlayer from '@/Components/Shared/VideoPlayer'

  export default {
    components: {
      Divider,
      MetaTags,
      Banner,
      Icon,
      InstructorCourse,
      // SerifHeader,
      VideoPlayer
    },
    mixins: [brightcove, metaTags],

    layout: Layout,

    props: {
      brightcove: {
        type: Object,
        required: true
      },
      instructor: {
        type: Object,
        default: () => {}
      }
    },

    data() {
      return {
        i18nScope: { scope: 'pages.instructors.show' }
      }
    },

    computed: {
      metaTags() {
        const name = this.instructor?.name || 'Instructor'
        const byline = this.instructor?.byline || 'Creative instructor at Create Academy'
        const description =
          this.instructor?.description || 'Learn from expert instructors at Create Academy'

        return {
          title: `${name} Online Courses | Create Academy`,
          description: byline,
          keywords: `${name.toLowerCase()}, instructor, creative courses, online learning`,
          schema: JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Person',
            name: name,
            description: description,
            jobTitle: 'Instructor',
            worksFor: {
              '@type': 'Organization',
              name: 'Create Academy',
              sameAs: typeof document !== 'undefined' ? document.location.origin : ''
            },
            image: this.instructor?.avatar,
            sameAs: this.existingSocials(this.instructor?.socials || []).map(social => social.url)
          })
        }
      }
    },

    methods: {
      possessiveForm(name) {
        const specialCases = ['jones'] // Weird possessive singlulars that end with s

        if (name.toLowerCase().endsWith('s')) {
          for (let special of specialCases) {
            if (name.toLowerCase().endsWith(special)) {
              return name + '\'s'
            }
          }
          return name + '\''
        }
        return name + '\'s'
      },
      existingSocials(socials) {
        return socials.filter(s => s.url && s.url.trim() !== '')
      }
    }
  }
</script>
