<template>
  <SplitBanner
    :title="bannerTitle"
    :description="category.description"
    :image="bannerImage"
    :image-id="`banner-image-${category.slug}`"
    :image-alt="`${category.name} banner image`"
    :background-color="backgroundColor"
  />
</template>

<script>
import SplitBanner from '@/Components/Shared/SplitBanner.vue'
import imgSrcset from '@/mixins/imgSrcset'

export default {
  components: {
    SplitBanner
  },

  mixins: [imgSrcset],

  props: {
    category: {
      type: Object,
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'bg-gray-100'
    }
  },

  computed: {
    bannerTitle() {
      switch(this.category.name) {
        case 'Home & Interiors':
          return 'Home & Interior Online Courses'
        case 'Gardening & Floristry':
          return 'Gardening & Floristry Courses'
        case 'Decorative Arts & Craft':
          return 'Decorative Arts & Crafts & Photography'
        case 'Food & Nutrition':
          return 'Food & Nutrition Online Courses'
        default:
          return this.category.name
      }
    },
    bannerImage() {
      if (this.category.bannerImagePath) {
        return this.imgSrcsetBanner(this.category.bannerImagePath)
      } else {
        return { url: null, srcset: [] }
      }
    }
  }
}
</script>
